@import 'overrides.less';

@black: #000000;
@gray-light: #ECECEC;
@gray: darken(@gray-light, 125%);
@text-muted: #777;
@brand-color: #336699;
@nav-bar-highlight: #E1E1E1;
@brand-white: #FDFDFD;

html {
  height: 100%;
}

body {
  min-height: 100%;
  background: linear-gradient(to bottom, #e9e9e9 0%, #f0f0f0 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.dropzone-previews {
  cursor:pointer;
}

.dropzone-dragfield {
  background-color: @gray-light;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
}

.commaClubImg {
  max-width: 50%;
  height: auto;
}

//.dz-max-files-reached {
//  pointer-events: none;
//  cursor:default;
//}

//.site-content{
//  margin: 60px 0 0 0;
//  min-height: calc(100vh - 70px);
//}

.footer{
  color: @brand-white;
  background-color: @brand-color;
  bottom: 0;
  height: 50px;

  & a {
    text-decoration: none;
    color: @brand-white !important;

    & :hover {
      color: @text-muted;
    }
  }
}

.site-content{
  margin-top: 60px;
  flex: 1;
}

nav a:hover{
  color: @text-muted;
}

ul .dropdown-menu {
  color: @black;
}

a.unstyled {
  color: #0f0f0f !important;
  cursor: auto;
}

.overflow-scroll {
  overflow: scroll;
}

#scrollToTop{
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  width: 40px;
  height: 40px;
  //background-color: @brand-white;
  //border: 3px solid @brand-color;
  border-radius: 30px;
  padding: 3px;

  & i {
    left: 4px;
    align-content: center;
  }
}

#show-all{
  //position: fixed;
  //top: 80px;
  //left: 50px;
  //width: 90px;
  //height: 38px;
  //font-family: "Lato", Helvetica, Arial, sans-serif;
  //font-size: 22px;
  //font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: @brand-color;
  //letter-spacing: -1px;
  //background-color: @brand-white;
  //border: 2px solid @brand-color;
  z-index: 99;

  //& :hover {
  //  background-color: @text-muted;
  //  color: darken(@brand-color, 25%);
  //}
}

@media screen and (max-width: 767px){
  .mobile-hidden{
    visibility: hidden;
    clear: both;
    display: none;
  }

  //.navbar-collapse{
  //  &.collapse{
  //    ul{
  //      li{
  //        a{
  //          color: #FFF !important;
  //        }
  //        i{
  //          color: #FFF !important;
  //        }
  //      }
  //    }
  //  }
  //}
  //
  //.icon-bar{
  //  background-color: #FFF !important;
  //}
}


// text mutators
.color-brand{color:@brand-color !important;}
.color-white{color:@brand-white !important;}


// point manipulation
.cursor-clickable {cursor: pointer;}


// DOM manipulation w/jquery
.display-none {display: none;}
.display-inline {display:inline;}

.baseline-align{vertical-align:baseline;}

.opac-25 {opacity: .25 !important;}
.opac-50 {opacity: .5 !important;}
.opac-75 {opacity: .75 !important;}
.opac-100 {opacity: 1 !important;}


// Scaffolding
.mt-neg-60{margin-top:-60px;}


.m-0{margin:0 !important;}
.mt-0{margin-top:0 !important;}
.mb-0{margin-bottom:0 !important;}

.m-2{margin:2px !important;}
.mt-2{margin-top:2px !important;}
.mr-2{margin-right:2px !important;}
.mb-2{margin-bottom:2px !important;}
.ml-2{margin-left:2px !important;}

.mr-5{margin-right:5px !important;}
.mt-5{margin-top:5px !important;}
.mt-neg-5{margin-top:-5px !important;}

.m-10{margin:10px !important;}
.mb-10{margin-bottom:10px !important;}
.mt-neg-10{margin-top:-10px !important;}

.mr-30{margin:30px !important;}

.p-0{padding: 0 !important;}
.pl-0{padding-left:0 !important;}
.pr-0{padding-right:0 !important;}
.pb-0{padding-bottom: 0 !important;}
.pt-0{padding-top:0 !important;}

.p-1{ padding: 1px !important; }
.pt-1{padding-top:1px !important;}
.pb-1{padding-bottom:1px !important;}
.pr-1{padding-right:1px !important;}
.pl-1{padding-left:1px !important;}

.p-3{ padding: 3px !important; }
.pt-3{padding-top:3px !important;}
.pb-3{padding-bottom:3px !important;}
.pr-3{padding-right:3px !important;}
.pl-3{padding-left:3px !important;}

.p-5{ padding: 5px !important; }
.pb-5{padding-bottom: 5px !important;}
.pt-5 {padding-top: 5px !important;}
.pl-5{padding-left:5px !important;}
.pr-5{padding-right:5px !important;}

.pb-10{padding-bottom: 10px !important;}
.p-10 {padding: 10px !important;}
.pb-10 {padding-bottom: 10px !important;}
.pt-10 {padding-top: 10px !important;}

.pt-20 {padding-top: 20px !important;}
.pb-20{padding-bottom: 20px !important;}

.pt-25 {padding-top: 25px !important;}

.pt-30 {padding-top: 30px !important;}

.pt-35 {padding-top: 35px !important;}

.pt-40 {padding-top: 40px !important;}

.pt-45 {padding-top: 45px !important;}

.pt-50 {padding-top: 50px !important;}

.pt-60 {padding-top: 60px !important;}

.pt-70 {padding-top: 70px !important;}

.pt-80 {padding-top: 80px !important;}

.pt-90 {padding-top: 90px !important;}

.pt-100 {
  padding-top: 100px !important;
}

.p-10{padding:10px !important;}
.pl-10{padding-left:10px !important;}
.pr-10{padding-right: 10px !important;}
.pb-10{padding-bottom: 10px !important;}
.pt-10{padding-top: 10px !important;}

.pr-25 {
  padding-right: 25px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.w-10 {
  width: 10px !important;
}

.w-15{width:15px !important;}

.w-20 {
  width: 20px !important;
}

.w-30 {
  width: 30px !important;
}

.w-40 {
  width: 40px !important;
}

.w-50 {
  width: 50px !important;
}

.w-60 {
  width: 60px !important;
}

.w-70 {
  width: 70px !important;
}

.w-80 {
  width: 80px !important;
}

.w-90 {
  width: 90px !important;
}

.w-100 {
  width: 100px !important;
}

.w-140 {
  width: 140px !important;
}

.w-160 {
  width: 160px !important;
}

.w-200 {
  width: 200px !important;
}
.w-220{width:220px !important;}
.w-250{width:250px !important;}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-675 {
  width: 675px !important;
}

.w-700 {
  width: 700px !important;
}

.wp-10{width:10% !important;}
.wp-20{width:20% !important;}
.wp-30{width:30% !important;}
.wp-85{width:85% !important;}
.wp-90{width:90% !important;}
.wp-100{width:100% !important;}





// height
.minh-220{min-height:220px !important;}

.maxh-470{max-height:470px !important;}

.h-20{height:20px !important;}
.h-40{height:40px !important;}
.h-50{height:50px !important;}
.h-100{height:100px !important;}

.h-220 {
  height: 220px !important;
}

.h-225 {
  height: 225px !important;
}

.h-230 {
  height: 230px !important;
}

.h-250 {
  height: 250px !important;
}

.h-270 {
  height: 270px !important;
}

.h-400{height:400px !important;}

.h-450 {height: 450px !important;}

.h-470 {
  height: 470px !important;
}

.h-700 {
  height: 700px !important;
}

.hp-100{height: 100% !important;}


.mh-230 {max-height: 230px !important;}

.h-500{height:500px !important;}
.mh-500{max-height:500px !important;}

.hero-font{
  color: #fff;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.3);
  font-weight: 500;
}

.landing-blog{
  background: rgba(200,54,54,0) !important;
  border: 0 !important;
}

.blog-feed-overflow{
  overflow:inherit !important;
}

.line-break-50 {
  padding-top: 25px;
  padding-bottom: 25px;
}

// borders

.b-0{border: 0 !important;}

.b-all{border: 1px solid @gray;}
.b-l-1{border-left:1px solid @gray-light;}
.b-r-1{border-right:1 px solid @gray-light;}
.b-b{border-bottom:2px solid @gray-light;}


.br-5{border-radius:5px !important;}

hr.paystub-title-break {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
  border-radius: 10px;
}


// fonts

.strong {
  font-weight: bold;
}




// colors

.bg-blue {
  background: #336699 !important;
  color: #ffffff !important;
}

.bg-white {
  background: #fff !important;
}

.bg-gray-light {
  background-color: @gray-light !important;
}

.bg-gray{background-color: @nav-bar-highlight !important;}

.editable {
  border: 1px dashed darken(@gray-light, 10%);
}


.input-transparent {
  background: transparent;
  border: 1px dashed @gray-light;
}


.flex-center{
  display: flex;
  align-items:center;
  justify-content:center;
}


.paystub-header {
  border-collapse: separate;
  border-radius: 5px;
  background-color: #006DCC;
  background-image: linear-gradient(to bottom, #00abff, #006DCC);
  color: white;

  h1 {
    padding-left: 5px;
    padding-top: 15px;
  }

  small {
      color: #EEEEEE;
  }

  .row {
    padding: 5px;
  }
}


.paystub-break {
  clear: both;
  width: 100%;
  background-color: #939393 !important;
  height: 4px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}


.datepicker-hot {
  position: relative;
  z-index: 900;
}

.nav .navbar-nav .navbar-right {
  z-index: 901;
}


.paystub {


  table {
    border-collapse: separate;
    border-radius: 0 5px;

    thead {


      th {
        background-color: #006DCC;
        color: white;

      }
    }


    tbody {


      td {
        background-color: #F0F8FF;
      }
    }

    tr {

      td:first-child,
      th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      td:last-child,
      th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}


ul .dropdown-menu{

  li {
    color: @black !important;
  }
}


.overridesTable {
  max-width: 900px;
  max-height: 500px;
}


.fixed_headers {
  width: 960px;
  table-layout: fixed;
  border-collapse: collapse;

  th, td {
    padding: 5px;
    text-align: left;
  }

  td:nth-child(1), th:nth-child(1) { min-width: 200px; }
  td:nth-child(2), th:nth-child(2) { min-width: 400px; }
  td:nth-child(3), th:nth-child(3) { min-width: 100px; }
  td:nth-child(4), th:nth-child(4) { min-width: 60px; }
  td:nth-child(5), th:nth-child(5) { width: 100px; }
  td:nth-child(6), th:nth-child(6) { width: 100px; }

  thead {
    background-color: @brand-color;
    color: @brand-white;
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 500px;
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }
  }
}

// HANDLING MOBILE DEVICES

.mobile-only{
  display:none;
}

@media screen and (max-width: 1024px){

  .mobile-only{
    display:block;
  }

  .desktop-only{
    display:none;
  }

}



// footer


.wrapper {
  min-height: 100%; 
  height: auto !important; 
  height: 100%;
}

// hyperlink styles

.icon-link {
  color: inherit;
  text-decoration: none;
  border: 0;
  padding: 2px;

    &:hover {
      color: #919191;
      cursor: pointer;
    }
}

.control-label {
  font-size: 16px;
}

.jsdata {
  display: none !important;
}

.jumbotron {
  margin: 5px 0 5px 0;
  background-color: lighten(@brand-color, 40%) !important;
  border: 1px solid @brand-color;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

td .cursor-clickable {

  &:hover{
    background-color: #ddd;
  }
}

.box {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.box-content {
  padding: 15px;

  .box-content-title {
    min-height: 50px;
    padding-top: 5px;
    padding-bottom: 20px;
  }
}

.box-default {
  border-color: #ddd;
}

.box-primary {
  background-color: lighten(@brand-color, 25%);
  color: @gray-light;

  > .blockquote {

    > .footer {
      color: @gray-light;
    }
  }
}

.box-title {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.box > .box-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}

.img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.yt-vid-container {
  position: relative;
  padding-bottom: 35%;
  padding-top: 0;
  height: 0;
  overflow:hidden;
}

.yt-vid-responsive {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  max-width: 853px;
  width: 95%;
  height: 100%;
}



.label {
  display: inline;
  padding: .2em .6em .3em;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

#carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  div {

    * {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    img{
      display: block;
      margin: 0 auto;
      max-height: 240px;
    }
  }
}



.carousel-arrows {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

[class^="fa-circle-o-notch"], [class*=" fa-circle-o-notch"]{
  display: inline-block;
}

td i .fa-circle-o-notch{
  text-align: center;
}

.social-icons span a i {
  padding: 0 5px 0 5px;
}

.navbar-default .navbar-nav {

  li {

    a {
      color: @brand-white !important;
    }

    :hover {
      background-color: lighten(@brand-color, 80%);
    }
  }

  li.active{
    a {
      color: @brand-color !important;
    }
  }

}


.menu-drawer {



  :hover {

  }
}